import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Sarah Choh please hire me.
        </p>
        <br></br>
        <br></br>
        <br></br>
        <img src="https://media.giphy.com/media/AYECTMLNS4o67dCoeY/giphy.gif" className="App-logo" alt="logo" />
        <br></br>
        <br></br>
        <br></br>

        <p>
          I'm good at this shit
        </p>
      </header>
    </div>
  );
}

export default App;
